@keyframes fade-in-out {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

.box {
	animation: fade-in-out var(--duration) infinite;
}
